import React from 'react';

const Events = () => {
return (
	<div
	style={{
		display: 'flex',
		justifyContent: 'Left',
		alignItems: 'Left',
		height: '100vh'
	}}
	>
	<h3>News and Upcoming Events...</h3>
	</div>
);
};

export default Events;
