import React from 'react';

const SignUp = () => {
return (
	<div
	style={{
		display: 'flex',
		justifyContent: 'Left',
		alignItems: 'Right',
		height: '100vh'
	}}
	>
	<h3>Alpha Version. Open to All Guests</h3>
	</div>
);
};

export default SignUp;
