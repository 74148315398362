import React from 'react';

const Blogs = () => {
return (
	<div
	style={{
		display: 'flex',
		justifyContent: 'Left',
		alignItems: 'Left',
		height: '100vh'
	}}
	>
	<h3>Welcome to GenAI Assistant Blogs</h3>
	
	</div>
);
};

export default Blogs;
