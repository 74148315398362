import React from 'react';

const Teams = () => {
return (
	<div
	style={{
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'Left',
		alignItems: 'Left',
		height: '100vh'
	}}
	>
	<div><h3>Ram Penke</h3>
		<h4>Founder, Passionate about products that help mass adaptation of advanced technologies</h4>
	</div>
	<div><h3>Satee Penke</h3>
	<h4>Founder, Enthusiast about Web3 and Meaverse teachnologies.</h4>
	</div>
	</div>
);
};

export default Teams;
